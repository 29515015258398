import { WebNotificationRouter } from "../subdomains/webNotification/routes/WebNotificationRouter";



import { NotificationRouter } from "../subdomains/notification/routes/NotificationRouter";
import { NotificationDomainLayout } from "../layouts";
import { notificationDomainRoutes } from "./notification.base.route";
import { Route, Routes } from "react-router-dom";
import { NotificationHistoryRouter } from "../subdomains/notificationHistory/routes/NotificationHistoryRouter";

export const NotificationDomainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<NotificationDomainLayout />}>
        <Route index element={<NotificationRouter />} />
        <Route
          path={`/${notificationDomainRoutes.subdomains.notification.base}/*`}
          element={<NotificationRouter />}
        />
        <Route
          path={`/${notificationDomainRoutes.subdomains.webNotification.base}/*`}
          element={<WebNotificationRouter />}
        />
        <Route
          path={`/${notificationDomainRoutes.subdomains.notificationHistory.base}/*`}
          element={<NotificationHistoryRouter />}
        />
        {/*
          // TODO: import subdomains
        */}
      </Route>
    </Routes>
  );
};
