import mainLocale from "../../apps/main/locales/en";

const locale = {
  main: {
    ...mainLocale,
  },
  aboutUs: "About Us",
  termsAndConditions: "Terms & Conditions",
  privacyPolicy: "Privacy Policy",
  cookiesPolicy: "Cookies Policy",
  resources: "Resources",
  allRightsReserved: "All rights reserved",
  rowsPerPage: "Rows per page",

  ////////////////////////////////////////////////////////////////
  // Validations
  ////////////////////////////////////////////////////////////////


  emailIsRequired: "Email is required",
  emailIsInvalid: "Email is invalid",
  nameIsRequired: "Name is required",
  titleIsRequired: "Title is required",
  profileImageIsRequired: "Profile Image is required",
  descriptionIsRequired: "Description is required",
  phoneNumberIsRequired: "Phone Number is required",
  quotaYupIsRequired: "Quota is required",
  dateYupIsRequired: "Date is required",
  statusYupIsRequired: "Status is required",
  locationYupIsRequired: "Location is required",
  detailsIsRequired: "Details is required",
  surnameIsRequired: "Surname is required",
  birthDateIsRequired: "Birth Date is required",
  genderIsRequired: "Gender is required",
  countryIsRequired: "Country is required",
  cityIsRequired: "City is required",
  addressIsRequired: "Address is required",
  contentIsRequired: "Content is required",
  readingTimeIsRequired: "Reading Time is required",
  tagsIsRequired: "Tags is required",
  referencesIsRequired: "References is required",
  relatedVideoIsRequired: "Related Video is required",
  authorIsRequired: "Author is required",
  categoryIsRequired: "Category is required",
  targetCategoryIsRequired: "Target Category is required",
  languageIsRequired: "Language is required",
  domainIsRequired: "Domain is required",
  targetIsRequired: "Target is required",
  questionIsRequired: "Question is required",
  answerIsRequired: "Answer is required",
  titleIsTooShort25: "Title is too short, minimum length is 25",
  titleIsTooLong100: "Title is too long, maximum length is 100",
  contentIsTooShort50: "Content is too short, minimum length is 50",
  contentIsTooLong250: "Content is too long, maximum length is 250",
  descriptionIsTooShort400: "Description is too short, minimum length is 400",
  descriptionIsTooLong20000: "Description is too long, maximum length is 20000",
  quotaMustBePositive: "Quota must be positive",
  quotaMustBeMultipleOf10: "Quota must be multiple of 10",
  participantsIsRequired: "Participants is required",
  startDateIsRequired: "Start Date is required",
  durationIsRequired: "Duration is required",
  quotaIsRequired: "Quota is required",
  lastApplicationDateIsRequired: "Last Application Date is required",
  statusIsRequired: "Status is required",
  locationIsRequired: "Location is required",
  typeIsRequired: "Type is required",
  participationLinkIsRequired: "Participation Link is required",
  durationMustBeMultipleOf5: "Duration must be multiple of 5",
  durationMustBePositive: "Duration must be positive",


  ////////////////////////////////////////////////////////////////
  // Buttons
  ////////////////////////////////////////////////////////////////

  cancel: "Cancel",
  save: "Save",
  update: "Update",
  delete: "Delete",
  create: "Create",
  add: "Add",
  close: "Close",

  ////////////////////////////////////////////////////////////////
  // Tables
  ////////////////////////////////////////////////////////////////

  actions: "Actions",
  title: "Title",
  content: "Content",
  category: "Category",
  target: "Target",
  status: "Status",
  date: "Date",
  location: "Location",
  details: "Details",
  name: "Name",
  surname: "Surname",
  birthDate: "Birth Date",
  fullName: "Full Name",
  email: "Email",
  subject: "Subject",
  createdAt: "Created At",
  userType: "User Type",
  page: "Page",
  news: "News",
  offer: "Offer",
  products: "Products",
  updatedAt: "Updated At",
  readingTime: "Reading Time",
  tags: "Tags",
  references: "References",
  version: "Version",
  domain: "Domain",
  type: "Type",
  language: "Language",
  duration: "Duration",
  startDate: "Start Date",
  participants: "Participants",
  quota: "Quota",
  question: "Question",
  answer: "Answer",
  lastApplicationDate: "Last Application Date",
  country: "Country",
  city: "City",
  company: "Company",
  download: "Download",
  sentUser: "Sent User",
  channelType: "Channel Type",

  ////////////////////////////////////////////////////////////////
  // Menus
  ////////////////////////////////////////////////////////////////
  dashboard: "Dashboard",
  blog: "Blog",
  blogs: "Blogs",
  categories: "Categories",
  authors: "Authors",
  faq: "FAQ",
  faqs: "FAQs",
  faqCategory: "Faq Categories",
  form: "Form",
  contactForm: "Contact Forms",
  requestForm: "Request Forms",
  subscriptionForm: "Subscription Forms",
  blogTargetCategories: "Target Categories",
  paymentProduct: "Product",

  ////////////////////////////////////////////////////////////////
  // Abbreviations
  ////////////////////////////////////////////////////////////////
  min: "min",
};

export default locale;

