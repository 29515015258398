import {
  IBaseGraphqlError,
  ILogoutInput,
  ILogoutResult,
  IRequestResetPasswordInput,
  IRequestResetPasswordResult,
  IConfirmRegistrationInput,
  IConfirmRegistrationResult,
  IRegisterByResult,
  IResendRegistrationByUserConfirmationInput,
  IResendRegistrationByUserConfirmationResult,
  IResetPasswordInput,
  IResetPasswordResult,
  IResetPasswordAfterRequestInput,
  IResetPasswordAfterRequestResult,
  IConfirmRegistrationByUserInput,
  IConfirmRegistrationByUserResult,
  IResendRegistrationByUserConfirmationRequest,
  IResendRegistrationConfirmationInput,
  logoutQuery,
  requestResetPasswordQuery,
  registerByQuery,
  confirmRegistrationQuery,
  resendRegistrationByUserConfirmationQuery,
  resetPasswordQuery,
  resetPasswordAfterRequestQuery,
  confirmRegistrationByUserQuery,
  resendRegistrationConfirmationQuery,
  ILogoutRequest,
  ILogoutResponse,
  IResetPasswordRequest,
  IResetPasswordResponse,
  IConfirmRegistrationRequest,
  IConfirmRegistrationResponse,
  IGraphqlVariables,
  IResentRegistrationByUserConfirmationResponse,
} from "corede-common";
import {
  IUserListInput,
  IUserListResponse,
  IUserListResult,
  userListQuery,
  IUserDetailOwnInput,
  IUserDetailOwnResponse,
  IUserDetailOwnResult,
  userDetailOwnQuery,
  IUserUpdateInput,
  IUserUpdateResponse,
  IUserUpdateResult,
  userUpdateQuery,
  ILoginInput,
  ILoginResponse,
  ILoginResult,
  ILoginRequest,
  loginQuery,
  IUserDetailOwnRequest,
  IUserListRequest,
} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { print } from "graphql";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";

const authApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries

    users: builder.query<IUserListResult, IGraphqlVariables<IUserListInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUserListRequest,
        IUserListResponse,
        IUserListResult,
        IUserListInput
      >({
        query: userListQuery,
        providesTags: ["users"],
      })
    ),

    user: builder.query<
      IUserDetailOwnResult,
      IGraphqlVariables<IUserDetailOwnInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IUserDetailOwnRequest,
        IUserDetailOwnResponse,
        IUserDetailOwnResult,
        IUserDetailOwnInput
      >({
        query: userDetailOwnQuery,
        providesTags: ["user"],
      })
    ),

    // mutations

    login: builder.mutation(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILoginRequest,
        ILoginResponse,
        ILoginResult,
        ILoginInput
      >({
        query: loginQuery,
      })
    ),

    logout: builder.mutation(
      EndpointQueryBuilder.BuildGraphqlQuery<
        ILogoutRequest,
        ILogoutResponse,
        ILogoutResult,
        ILogoutInput
      >({
        query: logoutQuery,
      })
    ),

    requestResetPassword: builder.mutation(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IResetPasswordRequest,
        IResetPasswordResponse,
        IResetPasswordResult,
        IResetPasswordInput
      >({
        query: resetPasswordQuery,
      })
    ),

    confirmRegistration: builder.mutation(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IConfirmRegistrationRequest,
        IConfirmRegistrationResponse,
        IConfirmRegistrationResult,
        IConfirmRegistrationInput
      >({
        query: confirmRegistrationQuery,
      })
    ),

    resendRegistrationByUserConfirmation: builder.mutation(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IResendRegistrationByUserConfirmationRequest,
        IResentRegistrationByUserConfirmationResponse,
        IResendRegistrationByUserConfirmationResult,
        IResendRegistrationByUserConfirmationInput
      >({
        query: resendRegistrationByUserConfirmationQuery,
      }),
    ),

    resetPassword: builder.mutation<IResetPasswordResult, IGraphqlVariables<IResetPasswordInput>>(
      EndpointQueryBuilder.BuildGraphqlQuery<
        IResetPasswordRequest,
        IResetPasswordResponse,
        IResetPasswordResult,
        IResetPasswordInput
      >({
        query: resetPasswordQuery,
        invalidatesTags: ['users', 'user'],
      }),
    ),

    resetPasswordAfterRequest: builder.mutation<
      IResetPasswordAfterRequestResult,
      IResetPasswordAfterRequestInput
    >({
      query: (variables: IResetPasswordAfterRequestInput) => {
        return {
          url: "",
          body: JSON.stringify({
            query: print(resetPasswordAfterRequestQuery),
            variables: {
              input: {
                token: variables.token,
                password: variables.password,
              },
            },
          }),
        };
      },
      transformResponse: (response: IResetPasswordAfterRequestResult) => {
        return response;
      },
      transformErrorResponse: (
        response: IBaseGraphqlError
      ): IBaseGraphqlError => {
        return response;
      },
      invalidatesTags: [],
    }),

    confirmRegistrationByUser: builder.mutation<
      IConfirmRegistrationByUserResult,
      IConfirmRegistrationByUserInput
    >({
      query: (variables: IConfirmRegistrationByUserInput) => {
        return {
          url: "",
          body: JSON.stringify({
            query: print(confirmRegistrationByUserQuery),
            variables: {
              input: {
                token: variables.token,
                code: variables.code,
                resetPasswordToken: variables.resetPasswordToken,
                password: variables.password,
              },
            },
          }),
        };
      },
      transformResponse: (response: IConfirmRegistrationByUserResult) => {
        return response;
      },
      transformErrorResponse: (response: IBaseGraphqlError) => {
        return response;
      },
      invalidatesTags: [],
    }),

    resendRegistrationByUser: builder.mutation<
      IResendRegistrationByUserConfirmationRequest,
      IResendRegistrationConfirmationInput
    >({
      query: (variables: IResendRegistrationConfirmationInput) => {
        return {
          url: "",
          body: JSON.stringify({
            query: print(resendRegistrationConfirmationQuery),
            variables: {
              input: {
                email: variables.email,
              },
            },
          }),
        };
      },
      transformResponse: (
        response: IResendRegistrationByUserConfirmationRequest
      ) => {
        return response;
      },
      transformErrorResponse: (response: IBaseGraphqlError) => {
        return response;
      },
      invalidatesTags: [],
    }),

    updateUser: builder.mutation<IUserUpdateResult, IUserUpdateInput>({
      query: (values: IUserUpdateInput) => {
        return {
          url: "",
          body: JSON.stringify({
            query: print(userUpdateQuery),
            variables: {
              input: values,
            },
          }),
        };
      },
      transformResponse: (response: IUserUpdateResult) => {
        return response;
      },
      transformErrorResponse: (response: IBaseGraphqlError) => {
        return response;
      },
      invalidatesTags: ["users", "user"],
    }),
  }),
});

export const {
  useUsersQuery,
  useLazyUsersQuery,
  useUserQuery,
  useLazyUserQuery,

  useLoginMutation,
  useLogoutMutation,
  useRequestResetPasswordMutation,
  useConfirmRegistrationMutation,
  useResendRegistrationByUserConfirmationMutation,
  useResetPasswordMutation,
  useResetPasswordAfterRequestMutation,
  useConfirmRegistrationByUserMutation,
  useResendRegistrationByUserMutation,
  useUpdateUserMutation,
} = authApi;
