import {
  Grid,
  List as ListMui,
  Stack,
  ListItem,
  Button,
  Skeleton,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Autocomplete,
  Chip,
  Typography,
  TextField,
} from "@mui/material";
import { useAppDispatch } from "context";
import { notificationActions } from "../../../context";
import {
  viewNotificationHistoryRoute,
} from "../../../routes/notification.base.route";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNotificationHistoriesQuery } from "../context/notificationHistory.api";
import { Delete as DeleteIcon, Edit, RemoveRedEye } from "@mui/icons-material";
import { Iconify, MyTablePagination } from "components";
import { IBaseError, IGraphqlVariables, Language, TNullable } from "corede-common";
import {
  IBaseNotificationHistoryEntity,
  INotificationHistoryListInput,
  NotificationHistoryStatus,
} from "corede-common-cocrm";
import { getCurrentLanguage } from "localization";
import { useNavigate } from "react-router-dom";
import Delete from "./Delete";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import moment from "moment";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs from "dayjs";
import { useUsersQuery } from "apps/auth";

const List = () => {
  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();

  // useStates
  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 0,
  });
  const [selectedItemForDelete, setSelectedItemForDelete] =
    useState<TNullable<IBaseNotificationHistoryEntity>>(null);
  const [variables, setVariables] = useState<
    IGraphqlVariables<INotificationHistoryListInput>
  >({
    input: {
      pagination: {
        pageSize: pagination.pageSize,
        page: pagination.page + 1,
      },
    },
  });

  // queries
  const {
    data: notificationHistoriesData,
    isLoading: notificationHistoriesIsLoading,
    error: notificationHistoriesError,
  } = useNotificationHistoriesQuery(variables);

  const {
    data: usersData,
    isLoading: usersIsLoading,
    error: usersError
  } = useUsersQuery({});

  // mutations

  // constants

  // action handlers

  const handleDelete = (item: IBaseNotificationHistoryEntity) => {
    setSelectedItemForDelete(item);
  };

  const handleView = (id: string) => {
    navigate(viewNotificationHistoryRoute(id));
  };

  const handleSortRequest = (sortName: string) => {

    sortName === "index" ?
      setVariables({
        ...variables,
        input: {
          ...variables.input,
          pagination: {
            ...variables.input?.pagination,
            sort: undefined
          }
        }
      })
      :
      setVariables({
        ...variables,
        input: {
          ...variables.input,
          pagination: {
            ...variables.input?.pagination,
            sort: {
              [sortName]: variables.input?.pagination?.sort?.[sortName] === 1 ? -1 : 1
            }
          }
        }
      })
  };

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      notificationHistoriesError as IBaseError,
      currentLanguage
    );
  }, [notificationHistoriesError, currentLanguage]);

  // useEffects.init
  useEffect(() => {
    setVariables({
      input: {
        pagination: {
          pageSize: pagination.pageSize,
          page: pagination.page + 1,
        },
      },
    });
  }, [pagination]);

  useEffect(() => {
    dispatch(notificationActions.setTitle(t("main.notification.notificationHistory.notificationHistories")));
    dispatch(notificationActions.setBackButton(false));
    dispatch(notificationActions.setRightButton(null));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Stack direction={"column"} gap={2} mb={2} border={"1px solid #CDCFD1"} borderRadius={2} p={2}>
        <Stack direction="row" gap={2}>
          <Typography variant="h5">{t("main.helpCenter.helpCenter.filters")}</Typography>
          <Stack direction="row" gap={1}>
            {
              Object.values(Language).map((language) => (
                <Chip
                  key={language}
                  label={language}
                  variant={variables.input?.filter?.languages?.[0] === language ? "filled" : "outlined"}
                  onClick={() => {
                    setVariables({
                      ...variables,
                      input: {
                        ...variables.input,
                        filter: {
                          ...(variables.input?.filter || {}),
                          languages: variables.input?.filter?.languages?.[0] === language ? undefined : [language],
                        },
                      },
                    });
                  }}
                />
              ))
            }
          </Stack>
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }} gap={2}>
          <TextField
            fullWidth
            label={t("main.notification.notificationHistory.notificationName")}
            value={variables.input?.filter?.notificationName}
            onChange={(e) => {
              setVariables({
                ...variables,
                input: {
                  ...variables.input,
                  filter: {
                    ...(variables.input?.filter || {}),
                    notificationName: e.target.value,
                  },
                },
              });
            }}
          />
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }} gap={2}>
          <Autocomplete
            id="sentUserId"
            isOptionEqualToValue={(option, value) => option._id === value._id}
            fullWidth
            getOptionLabel={(option) => option._id}
            options={usersData?.data || []}
            loading={usersIsLoading}
            renderInput={(params) => <TextField {...params} label={t("main.notification.notificationHistory.sentUser")} />}
            value={usersData?.data?.find((user) => user._id === variables.input?.filter?.sentUserId) || null}
            onChange={(e, value) => {
              setVariables({
                ...variables,
                input: {
                  ...variables.input,
                  filter: {
                    ...(variables.input?.filter || {}),
                    sentUserId: value?._id ?? undefined,
                  },
                },
              });
            }}
          />
          <Autocomplete
            id="statuses"
            isOptionEqualToValue={(option, value) => option === value}
            fullWidth
            getOptionLabel={(option) => option}
            options={Object.values(NotificationHistoryStatus)}
            loading={usersIsLoading}
            renderInput={(params) => <TextField {...params} label={t("main.notification.notificationHistory.status")} />}
            value={Object.values(NotificationHistoryStatus).find((status) => status === variables.input?.filter?.statuses?.[0]) || null}
            onChange={(e, value) => {
              setVariables({
                ...variables,
                input: {
                  ...variables.input,
                  filter: {
                    ...(variables.input?.filter || {}),
                    statuses: value ? [value] : undefined,
                  },
                },
              });
            }}
          />
          {/* <DateRangePicker
            sx={{ width: "100%" }}
            label={t("main.helpCenter.helpCenter.updatedAtDateFilter")}
            value={variables.input?.filter?.updatedAtDateFilter ?
              [dayjs(variables.input.filter.updatedAtDateFilter.from),
              dayjs(variables.input.filter.updatedAtDateFilter.to)]
              : undefined}
            onChange={(e) => {
              setVariables({
                ...variables,
                input: {
                  ...variables.input,
                  filter: {
                    ...(variables.input?.filter || {}),
                    updatedAtDateFilter: {
                      from: e?.[0]?.toDate() || undefined,
                      to: e?.[1]?.toDate() || undefined,
                    }
                  },
                },
              });
            }}
          /> */}
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Card sx={{ p: 2 }}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={variables.input?.pagination?.sort?.index !== undefined}
                      direction={variables.input?.pagination?.sort?.index === 1 ? "asc" : "desc"}
                      onClick={() => handleSortRequest("index")}
                    >#</TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={variables.input?.pagination?.sort?.notification?.name !== undefined}
                      direction={variables.input?.pagination?.sort?.notification?.name === 1 ? "asc" : "desc"}
                      onClick={() => handleSortRequest("notification.name")}
                    >{t("name")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={variables.input?.pagination?.sort?.notification?.domain !== undefined}
                      direction={variables.input?.pagination?.sort?.notification?.domain === 1 ? "asc" : "desc"}
                      onClick={() => handleSortRequest("notification.domain")}
                    >{t("domain")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={variables.input?.pagination?.sort?.notification?.type !== undefined}
                      direction={variables.input?.pagination?.sort?.notification?.type === 1 ? "asc" : "desc"}
                      onClick={() => handleSortRequest("notification.type")}
                    >{t("type")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={variables.input?.pagination?.sort?.channelType !== undefined}
                      direction={variables.input?.pagination?.sort?.channelType === 1 ? "asc" : "desc"}
                      onClick={() => handleSortRequest("channelType")}
                    >{t("channelType")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={variables.input?.pagination?.sort?.sentUser?._id !== undefined}
                      direction={variables.input?.pagination?.sort?.sentUser?._id === 1 ? "asc" : "desc"}
                      onClick={() => handleSortRequest("sentUser._id")}
                    >{t("sentUser")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={variables.input?.pagination?.sort?.createdAt !== undefined}
                      direction={variables.input?.pagination?.sort?.createdAt === 1 ? "asc" : "desc"}
                      onClick={() => handleSortRequest("createdAt")}
                    >{t("createdAt")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">{t("actions")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!notificationHistoriesIsLoading
                  ? notificationHistoriesData?.data?.map((notificationHistory, index) => (
                    <TableRow
                      key={notificationHistory._id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>{(pagination.page ?? 0) * pagination.pageSize + index + 1}</TableCell>
                      <TableCell>{notificationHistory.notification?._id}</TableCell>
                      <TableCell>{notificationHistory.notification?.domain}</TableCell>
                      <TableCell>{notificationHistory.notification?.type}</TableCell>
                      <TableCell>{notificationHistory.channelType}</TableCell>
                      <TableCell>{notificationHistory.sentUser?._id}</TableCell>
                      <TableCell>{moment(notificationHistory.createdAt).format("DD-MM-YYYY")}</TableCell>
                      <TableCell align="right">
                        <Stack direction="row" justifyContent={"flex-end"} gap={1} flexWrap={"wrap"}>
                          <Button
                            onClick={() => handleView(notificationHistory._id)}
                            variant="outlined"
                            disabled
                            size="small"
                            sx={{ height: 32, minWidth: 10 }}
                          >
                            <RemoveRedEye sx={{ fontSize: "16px" }} />
                          </Button>
                          <Button
                            onClick={() => handleDelete(notificationHistory)}
                            variant="outlined"
                            size="small"
                            color="error"
                            sx={{ height: 32, minWidth: 10 }}
                          >
                            <DeleteIcon sx={{ fontSize: "16px" }} />
                          </Button>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))
                  : [1, 2, 3].map((key) => (
                    <Stack
                      key={key}
                      direction="row"
                      justifyContent="space-between"
                      width={340}
                      alignItems={"center"}
                      p={2}
                    >
                      <Skeleton
                        variant="rectangular"
                        width={340}
                        height={60}
                        sx={{ borderRadius: 2 }}
                      />
                    </Stack>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Stack>

      <Stack
        direction="row"
        justifyContent={{ xs: "center", md: "flex-start" }}
        mt={2}
        mb={2}
      >
        <MyTablePagination
          count={notificationHistoriesData?.count ?? 0}
          pageSize={pagination.pageSize}
          page={pagination.page ?? 0}
          onPageChange={(e, page) => {
            setPagination({
              ...pagination,
              page: page,
            });
          }}
          onRowsPerPageChange={(e) => {
            setPagination({
              page: 0,
              pageSize: parseInt(e.target.value)
            });
          }}
        />
      </Stack>

      <Delete
        open={!!selectedItemForDelete}
        item={selectedItemForDelete!}
        onClose={() => {
          setSelectedItemForDelete(null);
        }}
      />
    </Grid>
  );
};

export default List;
