import {
  INotificationHistoryListInput,
  INotificationHistoryListRequest,
  INotificationHistoryListResponse,
  INotificationHistoryListResult,
  notificationHistoryDetailQuery,
  notificationHistoryListQuery,
  INotificationHistoryDetailInput,
  INotificationHistoryDetailRequest,
  INotificationHistoryDetailResponse,
  INotificationHistoryDetailResult,
  notificationHistoryDeleteQuery,
  INotificationHistoryDeleteInput,
  INotificationHistoryDeleteRequest,
  INotificationHistoryDeleteResponse,
  INotificationHistoryDeleteResult,
} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { notificationHistoryTags } from "./notificationHistory.tags";
import { IGraphqlVariables } from "corede-common";
import gql from "graphql-tag";

const notificationHistoryTagsFragment = gql` fragment NotificationHistoryListItemResultFragment on NotificationHistoryListItemResult {
  _id
  status
  notification {
    _id
  }
  customNotification
  channelType
  variableValues
  results
  isSentToTopic
  sentTopic
  language
  senderUser {
    _id
  }
  sentUser {
    _id
  }
  sentCustomUser
  organization {
    _id
  }
  createdAt
  updatedAt
}
`;


export const NotificationHistoryApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    NotificationHistories: builder.query<
      INotificationHistoryListResult,
      IGraphqlVariables<INotificationHistoryListInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        INotificationHistoryListRequest,
        INotificationHistoryListResponse,
        INotificationHistoryListResult,
        INotificationHistoryListInput
      >({
        query: notificationHistoryListQuery({
          fragment: notificationHistoryTagsFragment,
          fragmentName: "NotificationHistoryListItemResultFragment",
        }),
        providesTags: [notificationHistoryTags.notificationHistories],
      })
    ),

    NotificationHistory: builder.query<
      INotificationHistoryDetailResult,
      IGraphqlVariables<INotificationHistoryDetailInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        INotificationHistoryDetailRequest,
        INotificationHistoryDetailResponse,
        INotificationHistoryDetailResult,
        INotificationHistoryDetailInput
      >({
        query: notificationHistoryDetailQuery(),
        providesTags: [notificationHistoryTags.notificationHistory],
      })
    ),

    // mutation

    NotificationHistoryDelete: builder.mutation<
      INotificationHistoryDeleteResult,
      IGraphqlVariables<INotificationHistoryDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        INotificationHistoryDeleteRequest,
        INotificationHistoryDeleteResponse,
        INotificationHistoryDeleteResult,
        INotificationHistoryDeleteInput
      >({
        query: notificationHistoryDeleteQuery,
        invalidatesTags: [
          notificationHistoryTags.notificationHistories,
          notificationHistoryTags.notificationHistory,
        ],
      })
    ),
  }),
});

export const {
  useNotificationHistoriesQuery,
  useLazyNotificationHistoriesQuery,

  useNotificationHistoryQuery,
  useLazyNotificationHistoryQuery,

  useNotificationHistoryDeleteMutation,
} = NotificationHistoryApi;
