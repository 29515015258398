import { Route, Routes } from "react-router-dom";

import {List, View } from "../pages";

import { NotificationHistoryLayout } from "../layouts/NotificationHistoryLayout";
import { commonRoutes } from "routes/route.common";

export const NotificationHistoryRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<NotificationHistoryLayout />}>
        <Route index element={<List />} />
        <Route path="/:id" element={<View />} />
      </Route>
    </Routes>
  );
};
