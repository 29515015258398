import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  useWebNotificationCreateMutation,
} from "../context/webNotification.api";
import { useFormik } from "formik";
import { useAppDispatch } from "context";
import { notificationActions } from "../../../context";
import { IWebNotificationCreateInput, NotificationType } from "corede-common-cocrm";
import { IBaseError, IGraphqlVariables } from "corede-common";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { validateCreateWebNotificationInput } from "../validations/create.validation";
import { t } from "i18next";
import { RequestTransformerHelper } from "validations/request.transformer.helper";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { useUsersQuery } from "apps/auth";
import { useNotificationsQuery } from "../../notification";
import ReactQuill from "react-quill";

const Create = () => {
  // general
  const dispatch = useAppDispatch();
  const currentLanguage = getCurrentLanguage();

  // queries
  const { data: usersData, isLoading: usersIsLoading, error: usersError } = useUsersQuery({
   input: {
    pagination: {
      sort: { createdAt: -1 }
    }
   }
  })
  const { data: notificationsData, isLoading: notificationsIsLoading, error: notificationsError } = useNotificationsQuery({});

  // mutations
  const [
    createWebNotification,
    {
      data: createWebNotificationData,
      isLoading: createWebNotificationIsLoading,
      error: createWebNotificationError,
    },
  ] = useWebNotificationCreateMutation();

  // constants
  const { values, handleSubmit, handleBlur, setFieldValue, errors, resetForm } =
    useFormik({
      initialValues: {
        input: {
          userId: "",
          notificationId: "",
          language: currentLanguage,
          isRead: false,
          notificationType: NotificationType.alert,
          notificationPreview: {
            content: "",
            subject: "",
            name: "",
            data: {
              targetId: "",
              targetModel: "",
            },
          },
        },
      },
      enableReinitialize: true,
      validationSchema: validateCreateWebNotificationInput,
      onSubmit: (values: IGraphqlVariables<IWebNotificationCreateInput>) => {
        const transformedValues =
          RequestTransformerHelper.TransformAllEmptyStringFieldsToUndefined({
            input: values.input,
          });
        createWebNotification({
          ...values,
          input: transformedValues,
        });
      },
    });

  // useEffects.success
  useEffect(() => {
    if (createWebNotificationData) {
      console.log(createWebNotificationData, "data");
      resetForm();
      enqueueSnackbar(t("main.notification.webNotification.createdSuccessfully"), {
        variant: "success",
      });
    }
  }, [createWebNotificationData, resetForm]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      createWebNotificationError as IBaseError,
      currentLanguage
    );
  }, [createWebNotificationError, currentLanguage]);

  // useEffects.init
  useEffect(() => {
    dispatch(notificationActions.setTitle(t("main.notification.webNotification.create")));
    dispatch(notificationActions.setBackButton(true));
    dispatch(notificationActions.setRightButton(null));
  }, [dispatch]);

  useEffect(() => {
    if (notificationsData && values.input?.notificationId) {
      setFieldValue("input.notificationPreview.name", notificationsData?.data.find((notification: any) => notification._id === (values as any).input?.notificationId)?.name)
    }

  }, [notificationsData, values.input?.notificationId]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2} >

        <Autocomplete
          id="userId"
          value={usersData?.data.find((user: any) => user._id === (values as any).input?.userId) || null}
          onChange={(event: any, newValue: any) => {
            setFieldValue("input.userId", newValue ? newValue._id : "");
          }}
          onBlur={handleBlur}
          options={usersData?.data || []}
          getOptionLabel={(option) => option?.email || ""}
          loading={usersIsLoading}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("main.notification.webNotification.user")}
              error={!!(errors as any).input?.userId}
              helperText={(errors as any).input?.userId}
            />
          )}
        />

        <Autocomplete
          id="notificationId"
          value={notificationsData?.data.find((notification: any) => notification._id === (values as any).input?.notificationId) || null}
          onChange={(event: any, newValue: any) => {
            setFieldValue("input.notificationId", newValue ? newValue._id : "");
          }}
          onBlur={handleBlur}
          options={notificationsData?.data || []}
          getOptionLabel={(option) => option?.name || ""}
          loading={notificationsIsLoading}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("main.notification.webNotification.notification")}
              error={!!(errors as any).input?.notificationId}
              helperText={(errors as any).input?.notificationId}
            />
          )}
        />

        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                color="secondary"
                checked={(values as any).input.isRead}
                onChange={(event) => setFieldValue("input.isRead", event.target.checked)}
              />
            }
            label={t("main.notification.webNotification.isRead")}
          />
        </FormGroup>

        <Autocomplete
          id="notificationType"
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option) => option}
          options={Object.values(NotificationType)}
          renderInput={(params) => <TextField {...params} label={t("main.notification.notification.type")} />}
          value={(values as any).input.notificationType}
          onChange={(e, value) => {
            setFieldValue("input.notificationType", value);
          }}
          onBlur={() => handleBlur("input.notificationType")}
        />

        <Typography variant="h6" color="text.secondary">
          {t("main.notification.webNotification.preview")}
        </Typography>

        <TextField
          fullWidth
          name="input.notificationPreview.name"
          label={t("main.notification.notification.name")}
          value={(values as any).input?.notificationPreview?.name}
          onChange={(e) => setFieldValue("input.notificationPreview.name", e.target.value)}
          onBlur={() => handleBlur("input.notificationPreview.name")}
          error={!!(errors as any).input?.notificationPreview?.name}
          helperText={(errors as any).input?.notificationPreview?.name}
        />
        <TextField
          fullWidth
          name="input.notificationPreview.subject"
          label={t("main.notification.notification.subject")}
          value={(values as any)?.input?.notificationPreview?.subject || ""}
          onChange={(e) => {
            setFieldValue("input.notificationPreview.subject", e.target.value);
          }}
          onBlur={() => handleBlur("input.notificationPreview.subject")}
          error={!!(errors as any)?.input?.notificationPreview?.subject}
          helperText={(errors as any)?.input?.notificationPreview?.subject}
        />
        <ReactQuill
          theme="snow"
          value={(values as any)?.input?.notificationPreview?.content || ""}
          onChange={(value) => {
            setFieldValue("input.notificationPreview.content", value);
          }}
          onBlur={() => handleBlur("input.notificationPreview.content")}
          placeholder={t("main.notification.notification.content")}
          scrollingContainer={".ql-editor"}
          style={{ height: 300, marginBottom: 40, marginTop: 10, paddingBottom: 10 }}
        />
        {(errors as any)?.input?.notificationPreview?.content && (
          <Typography color="error" variant="caption">
            {(errors as any)?.input?.notificationPreview?.content}
          </Typography>
        )}

        <TextField
          fullWidth
          name="input.notificationPreview.data.targetId"
          label={t("main.notification.webNotification.targetId")}
          value={(values as any).input?.notificationPreview?.data.targetId}
          onChange={(e) => setFieldValue("input.notificationPreview.data.targetId", e.target.value)}
          onBlur={() => handleBlur("input.notificationPreview.data.targetId")}
          error={!!(errors as any).input?.notificationPreview?.data.targetId}
          helperText={(errors as any).input?.notificationPreview?.data.targetId}
        />

        <TextField
          fullWidth
          name="input.notificationPreview.data.targetModel"
          label={t("main.notification.webNotification.targetModel")}
          value={(values as any).input?.notificationPreview?.data.targetModel}
          onChange={(e) => setFieldValue("input.notificationPreview.data.targetModel", e.target.value)}
          onBlur={() => handleBlur("input.notificationPreview.data.targetModel")}
          error={!!(errors as any).input?.notificationPreview?.data.targetModel}
          helperText={(errors as any).input?.notificationPreview?.data.targetModel}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
          disabled={createWebNotificationIsLoading}
        >
          {createWebNotificationIsLoading ? (
            <CircularProgress size="1rem" sx={{ mr: 2 }} />
          ) : (
            ""
          )}
          Create
        </Button>
      </Stack>
    </Grid >
  );
};

export default Create;
