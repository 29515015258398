const locale = {
  notificationHistory: "Notification History",
  notificationHistories: "Notification Histories",
  create: "Create Notification History",
  update: "Update Notification History",
  delete: "Delete Notification History",
  view: "View Notification History",
  list: "List Notification Histories",
  createdSuccessfully: "Notification History created successfully",
  updatedSuccessfully: "Notification History updated successfully",
  deleteConfirm: "Are you sure you want to delete: ",
  deletedSuccessfully: "Notification History deleted successfully",
  user: "User",
  notification: "Notification",
  isRead: "Is Read",
  preview: "Preview",
  targetId: "Target Id",
  targetModel: "Target Model",
  send: "Send Notification History",
};

export default locale;
