import {
  INotificationListInput,
  INotificationListRequest,
  INotificationListResponse,
  INotificationListResult,
  notificationDetailQuery,
  notificationListQuery,
  INotificationDetailInput,
  INotificationDetailRequest,
  INotificationDetailResponse,
  INotificationDetailResult,
  INotificationCreateInput,
  INotificationCreateRequest,
  INotificationCreateResponse,
  INotificationCreateResult,
  notificationCreateQuery,
  INotificationUpdateInput,
  INotificationUpdateRequest,
  INotificationUpdateResponse,
  INotificationUpdateResult,
  notificationUpdateQuery,
  INotificationUpdateFilterInput,
  notificationDeleteQuery,
  INotificationDeleteInput,
  INotificationDeleteRequest,
  INotificationDeleteResponse,
  INotificationDeleteResult,
  INotificationListOwnInput,
} from "corede-common-cocrm";
import { commonApi } from "context/commonApi";
import { EndpointQueryBuilder } from "apps/helper/endpoint-query.builder";
import { notificationTags } from "./notification.tags";
import { IGraphqlVariables } from "corede-common";

export const notificationApi = commonApi.injectEndpoints({
  endpoints: (builder) => ({
    // queries
    notifications: builder.query<
      INotificationListResult,
      IGraphqlVariables<INotificationListOwnInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        INotificationListRequest,
        INotificationListResponse,
        INotificationListResult,
        INotificationListOwnInput
      >({
        query: notificationListQuery,
        providesTags: [notificationTags.notifications],
      })
    ),

    notification: builder.query<
      INotificationDetailResult,
      IGraphqlVariables<INotificationDetailInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        INotificationDetailRequest,
        INotificationDetailResponse,
        INotificationDetailResult,
        INotificationDetailInput
      >({
        query: notificationDetailQuery,
        providesTags: [notificationTags.notification],
      })
    ),

    // mutation
    createNotification: builder.mutation<
      INotificationCreateResult,
      IGraphqlVariables<INotificationCreateInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        INotificationCreateRequest,
        INotificationCreateResponse,
        INotificationCreateResult,
        INotificationCreateInput
      >({
        query: notificationCreateQuery,
        invalidatesTags: [notificationTags.notifications],
      })
    ),

    updateNotification: builder.mutation<
      INotificationUpdateResult,
      IGraphqlVariables<
        INotificationUpdateInput,
        INotificationUpdateFilterInput
      >
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        INotificationUpdateRequest,
        INotificationUpdateResponse,
        INotificationUpdateResult,
        INotificationUpdateInput,
        INotificationUpdateFilterInput
      >({
        query: notificationUpdateQuery,
        invalidatesTags: [
          notificationTags.notifications,
          notificationTags.notification,
        ],
      })
    ),

    deleteNotification: builder.mutation<
      INotificationDeleteResult,
      IGraphqlVariables<INotificationDeleteInput>
    >(
      EndpointQueryBuilder.BuildGraphqlQuery<
        INotificationDeleteRequest,
        INotificationDeleteResponse,
        INotificationDeleteResult,
        INotificationDeleteInput
      >({
        query: notificationDeleteQuery,
        invalidatesTags: [
          notificationTags.notifications,
          notificationTags.notification,
        ],
      })
    ),
  }),
});

export const {
  useNotificationsQuery,
  useLazyNotificationsQuery,

  useNotificationQuery,
  useLazyNotificationQuery,

  useCreateNotificationMutation,
  useUpdateNotificationMutation,
  useDeleteNotificationMutation,
} = notificationApi;
