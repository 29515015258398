import notificationLocale from "../subdomains/notification/locales/tr";
import webNotificationLocale from "../subdomains/webNotification/locales/tr";
import notificationHistoryLocale from "../subdomains/notificationHistory/locales/tr";

const locale = {
  ////////////////////////////////////////////////////////////////
  // domain
  ////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////
  // subDomains
  ////////////////////////////////////////////////////////////////

  notification: {
    ...notificationLocale,
  },

  // TODO: import subdomain locales
  webNotification: {
    ...webNotificationLocale,
  },

  notificationHistory: {
    ...notificationHistoryLocale
  }
};

export default locale;
