import { CircularProgress, Grid, Stack, TextField } from "@mui/material";
import { useNotificationHistoryQuery } from "../context/notificationHistory.api";
import { useAppDispatch } from "context";
import { notificationActions } from "../../../context";
import { useEffect } from "react";
import { t } from "i18next";
import { useNavigate, useParams } from "react-router-dom";
import { IBaseError } from "corede-common";
import { getCurrentLanguage } from "localization";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { listNotificationHistoryRoute } from "../../../routes/notification.base.route";

const View = () => {
  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();
  const { id } = useParams();
  if (!id) {
    navigate(listNotificationHistoryRoute());
  }
  // queries
  const {
    data: viewNotificationHistoryData,
    isLoading: viewNotificationHistoryIsLoading,
    error: viewNotificationHistoryError,
  } = useNotificationHistoryQuery({
    input: { _id: id! },
  });

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      viewNotificationHistoryError as IBaseError,
      currentLanguage
    );
  }, [viewNotificationHistoryError, currentLanguage]);

  useEffect(() => {
    dispatch(notificationActions.setTitle(t("main.notification.notificationHistory.view")));
    dispatch(notificationActions.setBackButton(true));
    dispatch(notificationActions.setRightButton(null));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2} maxWidth={400}>
        {viewNotificationHistoryIsLoading ? (
          <CircularProgress />
        ) : (
          <Stack direction="column" gap={2}>
            {/*
              // TODO: add field views
            */}
          </Stack>
        )}
      </Stack>
    </Grid>
  );
};

export default View;
