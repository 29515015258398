import {
  Grid,
  List as ListMui,
  Stack,
  ListItem,
  Button,
  Skeleton,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { useAppDispatch } from "context";
import { notificationActions } from "../../../context";
import {
  createWebNotificationRoute,
  updateWebNotificationRoute,
  viewWebNotificationRoute,
} from "../../../routes/notification.base.route";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useWebNotificationsQuery } from "../context/webNotification.api";
import { Delete as DeleteIcon, Edit, RemoveRedEye } from "@mui/icons-material";
import { Iconify, MyTablePagination } from "components";
import { IBaseError, IGraphqlVariables, TNullable } from "corede-common";
import {
  IBaseWebNotificationEntity,
  IWebNotificationListInput,
} from "corede-common-cocrm";
import { getCurrentLanguage } from "localization";
import { useNavigate } from "react-router-dom";
import Delete from "./Delete";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import moment from "moment";

const List = () => {
  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();

  // useStates
  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 0,
  });
  const [selectedItemForDelete, setSelectedItemForDelete] =
    useState<TNullable<IBaseWebNotificationEntity>>(null);
  const [variables, setVariables] = useState<
    IGraphqlVariables<IWebNotificationListInput>
  >({
    input: {
      pagination: {
        pageSize: pagination.pageSize,
        page: pagination.page + 1,
      },
    },
  });

  // queries
  const {
    data: webNotificationsData,
    isLoading: webNotificationsIsLoading,
    error: webNotificationsError,
  } = useWebNotificationsQuery(variables);

  // mutations

  // constants

  // action handlers
  const handleEdit = (id: string) => {
    navigate(updateWebNotificationRoute(id));
  };

  const handleDelete = (item: IBaseWebNotificationEntity) => {
    setSelectedItemForDelete(item);
  };

  const handleView = (id: string) => {
    navigate(viewWebNotificationRoute(id));
  };

  const handleSortRequest = (sortName: string) => {

    sortName === "index" ?
      setVariables({
        ...variables,
        input: {
          ...variables.input,
          pagination: {
            ...variables.input?.pagination,
            sort: undefined
          }
        }
      })
      :
      setVariables({
        ...variables,
        input: {
          ...variables.input,
          pagination: {
            ...variables.input?.pagination,
            sort: {
              [sortName]: variables.input?.pagination?.sort?.[sortName] === 1 ? -1 : 1
            }
          }
        }
      })
  };

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      webNotificationsError as IBaseError,
      currentLanguage
    );
  }, [webNotificationsError, currentLanguage]);

  // useEffects.init
  useEffect(() => {
    setVariables({
      input: {
        pagination: {
          pageSize: pagination.pageSize,
          page: pagination.page + 1,
        },
      },
    });
  }, [pagination]);

  useEffect(() => {
    dispatch(notificationActions.setTitle(t("main.notification.webNotification.webNotifications")));
    dispatch(notificationActions.setBackButton(false));
    dispatch(
      notificationActions.setRightButton({
        title: t(`main.notification.webNotification.send`),
        path: createWebNotificationRoute(),
        icon: null,
      })
    );
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Stack direction="row" justifyContent="space-between">
        <Card sx={{ p: 2 }}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={variables.input?.pagination?.sort?.index !== undefined}
                      direction={variables.input?.pagination?.sort?.index === 1 ? "asc" : "desc"}
                      onClick={() => handleSortRequest("index")}
                    >#</TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={variables.input?.pagination?.sort?.notificationPreview?.name !== undefined}
                      direction={variables.input?.pagination?.sort?.notificationPreview?.name === 1 ? "asc" : "desc"}
                      onClick={() => handleSortRequest("notificationPreview.name")}
                    >{t("name")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={variables.input?.pagination?.sort?.notificationPreview.subject !== undefined}
                      direction={variables.input?.pagination?.sort?.notificationPreview.subject === 1 ? "asc" : "desc"}
                      onClick={() => handleSortRequest("notificationPreview.subject")}
                    >{t("subject")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={variables.input?.pagination?.sort?.createdAt !== undefined}
                      direction={variables.input?.pagination?.sort?.createdAt === 1 ? "asc" : "desc"}
                      onClick={() => handleSortRequest("createdAt")}
                    >{t("createdAt")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={variables.input?.pagination?.sort?.updatedAt !== undefined}
                      direction={variables.input?.pagination?.sort?.updatedAt === 1 ? "asc" : "desc"}
                      onClick={() => handleSortRequest("updatedAt")}
                    >{t("updatedAt")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">{t("actions")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!webNotificationsIsLoading
                  ? webNotificationsData?.data?.map((webNotification, index) => (
                    <TableRow
                      key={webNotification._id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>{(pagination.page ?? 0) * pagination.pageSize + index + 1}</TableCell>
                      <TableCell>{webNotification.notificationPreview?.name}</TableCell>
                      <TableCell>{webNotification.notificationPreview?.subject}</TableCell>
                      <TableCell>{moment(webNotification.createdAt).format("DD-MM-YYYY")}</TableCell>
                      <TableCell>{moment(webNotification.updatedAt).format("DD-MM-YYYY")}</TableCell>
                      <TableCell align="right">
                        <Stack direction="row" justifyContent={"flex-end"} gap={1} flexWrap={"wrap"}>
                          {/* <Button
                            onClick={() => handleView(webNotification._id)}
                            variant="outlined"
                            size="small"
                            sx={{ height: 32, minWidth: 10 }}
                          >
                            <RemoveRedEye sx={{ fontSize: "16px" }} />
                          </Button> */}
                          <Button
                            onClick={() => handleEdit(webNotification._id)}
                            variant="outlined"
                            size="small"
                            sx={{ height: 32, minWidth: 10 }}
                          >
                            <Edit sx={{ fontSize: "16px" }} />
                          </Button>
                          <Button
                            onClick={() => handleDelete(webNotification)}
                            variant="outlined"
                            size="small"
                            color="error"
                            sx={{ height: 32, minWidth: 10 }}
                          >
                            <DeleteIcon sx={{ fontSize: "16px" }} />
                          </Button>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))
                  : [1, 2, 3].map((key) => (
                    <Stack
                      key={key}
                      direction="row"
                      justifyContent="space-between"
                      width={340}
                      alignItems={"center"}
                      p={2}
                    >
                      <Skeleton
                        variant="rectangular"
                        width={340}
                        height={60}
                        sx={{ borderRadius: 2 }}
                      />
                    </Stack>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Stack>

      <Stack
        direction="row"
        justifyContent={{ xs: "center", md: "flex-start" }}
        mt={2}
        mb={2}
      >
        <MyTablePagination
          count={webNotificationsData?.count ?? 0}
          pageSize={pagination.pageSize}
          page={pagination.page ?? 0}
          onPageChange={(e, page) => {
            setPagination({
              ...pagination,
              page: page,
            });
          }}
          onRowsPerPageChange={(e) => {
            setPagination({
              page: 0,
              pageSize: parseInt(e.target.value)
            });
          }}
        />
      </Stack>

      <Delete
        open={!!selectedItemForDelete}
        item={selectedItemForDelete!}
        onClose={() => {
          setSelectedItemForDelete(null);
        }}
      />
    </Grid>
  );
};

export default List;
