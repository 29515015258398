import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  useWebNotificationUpdateMutation,
  useWebNotificationQuery,
} from "../context/webNotification.api";
import { useFormik } from "formik";
import { useAppDispatch } from "context";
import { notificationActions } from "../../../context";
import {
  IWebNotificationUpdateInput,
  NotificationType,
} from "corede-common-cocrm";
import { IBaseError, IGraphqlVariables } from "corede-common";
import { useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { getCurrentLanguage } from "localization";
import { t } from "i18next";
import { useParams, useNavigate } from "react-router-dom";
import { validateUpdateWebNotificationInput } from "../validations/update.validation";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import { listWebNotificationRoute } from "../../../routes/notification.base.route";
import ReactQuill from "react-quill";
import { useNotificationsQuery } from "../../notification";
import { useUsersQuery } from "apps/auth";
import parse from "html-react-parser";

const Update = () => {
  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();
  const { id } = useParams();
  if (!id) {
    navigate(listWebNotificationRoute());
  }

  // queries
  const {
    data: webNotificationData,
    isLoading: webNotificationIsLoading,
    error: webNotificationError,
  } = useWebNotificationQuery({ input: { _id: id! } });
  const {
    data: notificationsData,
    isLoading: notificationsIsLoading,
    error: notificationsError,
  } = useNotificationsQuery({});
  const {
    data: usersData,
    isLoading: usersIsLoading,
    error: usersError,
  } = useUsersQuery({
    input: {
      pagination: {
        sort: { createdAt: -1 },
      },
    },
  });

  // mutations
  const [
    updateWebNotification,
    {
      data: updateWebNotificationData,
      isLoading: updateWebNotificationIsLoading,
      error: updateWebNotificationError,
    },
  ] = useWebNotificationUpdateMutation();

  // constants
  const {
    values,
    handleSubmit,
    handleBlur,
    setFieldValue,
    errors,
    resetForm,
    setValues,
  } = useFormik({
    initialValues: {
      input: {
        userId: "",
        notificationId: "",
        isRead: false,
        notificationType: NotificationType.alert,
        notificationPreview: {
          content: "",
          subject: "",
          name: "",
          data: {
            targetId: "",
            targetModel: "",
          },
        },
      },
    },
    enableReinitialize: true,
    validationSchema: validateUpdateWebNotificationInput,
    onSubmit: (values: IGraphqlVariables<IWebNotificationUpdateInput>) => {
      updateWebNotification({
        filter: { _id: id! },
        input: values.input,
      });
    },
  });

  // useEffects.success
  useEffect(() => {
    console.log(webNotificationData);
    if (webNotificationData) {
      setValues({
        input: {
          isRead: webNotificationData.isRead,
          notificationId: webNotificationData.notification?._id,
          userId: webNotificationData.user?._id,
          notificationType: webNotificationData.notificationType,
          notificationPreview: webNotificationData.notificationPreview,
        },
      });
    }
  }, [webNotificationData, setValues]);

  useEffect(() => {
    if (updateWebNotificationData) {
      resetForm();
      enqueueSnackbar(
        t("main.notification.webNotification.updatedSuccessfully"),
        {
          variant: "success",
        }
      );
    }
  }, [updateWebNotificationData, resetForm]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      updateWebNotificationError as IBaseError,
      currentLanguage
    );
  }, [updateWebNotificationError, currentLanguage]);

  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      webNotificationError as IBaseError,
      currentLanguage
    );
  }, [webNotificationError, currentLanguage]);

  // useEffects.init
  useEffect(() => {
    dispatch(
      notificationActions.setTitle(
        t("main.notification.webNotification.update")
      )
    );
    dispatch(notificationActions.setBackButton(true));
    dispatch(notificationActions.setRightButton(null));
  }, [dispatch]);

  return (
    <Grid item xs={12}>
      <Stack direction="column" gap={2} mt={2}>
        {webNotificationIsLoading ? (
          <CircularProgress />
        ) : (
          <Stack direction="column" gap={2}>
            <Autocomplete
              id="userId"
              value={
                usersData?.data.find(
                  (user: any) => user._id === (values as any).input?.userId
                ) || null
              }
              onChange={(event: any, newValue: any) => {
                setFieldValue("input.userId", newValue ? newValue._id : "");
              }}
              onBlur={handleBlur}
              options={usersData?.data || []}
              getOptionLabel={(option) => option?.email || ""}
              loading={usersIsLoading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("main.notification.webNotification.user")}
                  error={!!(errors as any).input?.userId}
                  helperText={(errors as any).input?.userId}
                />
              )}
            />

            <Autocomplete
              id="notificationId"
              value={
                notificationsData?.data.find(
                  (notification: any) =>
                    notification._id === (values as any).input?.notificationId
                ) || null
              }
              onChange={(event: any, newValue: any) => {
                setFieldValue(
                  "input.notificationId",
                  newValue ? newValue._id : ""
                );
              }}
              onBlur={handleBlur}
              options={notificationsData?.data || []}
              getOptionLabel={(option) => option?.name || ""}
              loading={notificationsIsLoading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("main.notification.webNotification.notification")}
                  error={!!(errors as any).input?.notificationId}
                  helperText={(errors as any).input?.notificationId}
                />
              )}
            />

            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    color="secondary"
                    checked={(values as any).input.isRead}
                    onChange={(event) =>
                      setFieldValue("input.isRead", event.target.checked)
                    }
                  />
                }
                label={t("main.notification.webNotification.isRead")}
              />
            </FormGroup>

            <Autocomplete
              id="notificationType"
              isOptionEqualToValue={(option, value) => option === value}
              getOptionLabel={(option) => option}
              options={Object.values(NotificationType)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("main.notification.notification.type")}
                />
              )}
              value={(values as any).input.notificationType}
              onChange={(e, value) => {
                setFieldValue("input.notificationType", value);
              }}
              onBlur={() => handleBlur("input.notificationType")}
            />

            <Typography variant="h6" color="text.secondary">
              {t("main.notification.webNotification.preview")}
            </Typography>

            <TextField
              fullWidth
              name="input.notificationPreview.name"
              label={t("main.notification.notification.name")}
              value={(values as any).input?.notificationPreview?.name}
              onChange={(e) =>
                setFieldValue("input.notificationPreview.name", e.target.value)
              }
              onBlur={() => handleBlur("input.notificationPreview.name")}
              error={!!(errors as any).input?.notificationPreview?.name}
              helperText={(errors as any).input?.notificationPreview?.name}
            />
            <TextField
              fullWidth
              name="input.notificationPreview.subject"
              label={t("main.notification.notification.subject")}
              value={(values as any)?.input?.notificationPreview?.subject || ""}
              onChange={(e) => {
                setFieldValue(
                  "input.notificationPreview.subject",
                  e.target.value
                );
              }}
              onBlur={() => handleBlur("input.notificationPreview.subject")}
              error={!!(errors as any)?.input?.notificationPreview?.subject}
              helperText={(errors as any)?.input?.notificationPreview?.subject}
            />
            {/* <ReactQuill
              theme="snow"
              value={(values as any)?.input?.notificationPreview?.content || ""}
              onChange={(value) => {
                setFieldValue("input.notificationPreview.content", value);
              }}
              onBlur={() => handleBlur("input.notificationPreview.content")}
              placeholder={t("main.notification.notification.content")}
              scrollingContainer={".ql-editor"}
              style={{ height: 300, marginBottom: 40, marginTop: 10, paddingBottom: 10 }}
            />
            {(errors as any)?.input?.notificationPreview?.content && (
              <Typography color="error" variant="caption">
                {(errors as any)?.input?.notificationPreview?.content}
              </Typography>
            )} */}
            <Stack>
              {parse((values as any)?.input.notificationPreview.content || "")}
            </Stack>
            <TextField
              fullWidth
              name="input.notificationPreview.content"
              label={t("main.notification.notification.content")}
              value={(values as any)?.input.notificationPreview.content || ""}
              onChange={(e) => {
                setFieldValue(
                  "input.notificationPreview.content",
                  e.target.value
                );
              }}
              multiline
              rows={15}
            />

            <TextField
              fullWidth
              name="input.notificationPreview.data.targetId"
              label={t("main.notification.webNotification.targetId")}
              value={(values as any).input?.notificationPreview?.data?.targetId}
              onChange={(e) =>
                setFieldValue(
                  "input.notificationPreview.data.targetId",
                  e.target.value
                )
              }
              onBlur={() =>
                handleBlur("input.notificationPreview.data.targetId")
              }
              error={
                !!(errors as any).input?.notificationPreview?.data?.targetId
              }
              helperText={
                (errors as any).input?.notificationPreview?.data?.targetId
              }
            />

            <TextField
              fullWidth
              name="input.notificationPreview.data.targetModel"
              label={t("main.notification.webNotification.targetModel")}
              value={
                (values as any).input?.notificationPreview?.data?.targetModel
              }
              onChange={(e) =>
                setFieldValue(
                  "input.notificationPreview.data.targetModel",
                  e.target.value
                )
              }
              onBlur={() =>
                handleBlur("input.notificationPreview.data.targetModel")
              }
              error={
                !!(errors as any).input?.notificationPreview?.data?.targetModel
              }
              helperText={
                (errors as any).input?.notificationPreview?.data?.targetModel
              }
            />

            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}
              disabled={updateWebNotificationIsLoading}
            >
              {updateWebNotificationIsLoading ? (
                <CircularProgress size="1rem" sx={{ mr: 2 }} />
              ) : (
                ""
              )}
              {t("update")}
            </Button>
          </Stack>
        )}
      </Stack>
    </Grid>
  );
};

export default Update;
