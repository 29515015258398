import {
  Grid,
  Stack,
  Button,
  Skeleton,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  ClickAwayListener,
  TextField,
  Autocomplete,
  Typography,
  TableSortLabel,
} from "@mui/material";
import { useAppDispatch } from "context";
import { contactFormActions } from "../../../context";
import {
  updateContactFormRoute,
  viewContactFormRoute,
} from "../../../routes/contactForm.base.route";
import { t } from "i18next";
import { useEffect, useState } from "react";
import {
  useContactFormsQuery,
  useUpdateContactFormMutation,
} from "../context/contactForm.api";
import { useUsersQuery } from "apps/auth/context";
import { Delete as DeleteIcon, Edit, RemoveRedEye } from "@mui/icons-material";
import { MyTablePagination, StatusPopper } from "components";
import { IBaseError, IGraphqlVariables, TNullable } from "corede-common";
import {
  ContactFormStatus,
  ContactFormType,
  IBaseContactFormEntity,
  IContactFormsInput,
} from "corede-common-cocrm";
import { getCurrentLanguage } from "localization";
import { useNavigate } from "react-router-dom";
import Delete from "./Delete";
import { DefaultErrorHandlerUseEffect } from "utils/useEffect.helper";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs from "dayjs";

const List = () => {
  // general
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentLanguage = getCurrentLanguage();

  // useStates
  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 0,
  });
  const [selectedItemForDelete, setSelectedItemForDelete] =
    useState<TNullable<IBaseContactFormEntity>>(null);
  const [variables, setVariables] = useState<
    IGraphqlVariables<IContactFormsInput>
  >({
    input: {
      filter: {
        fullName: undefined,
        email: undefined,
        statuses: undefined,
        languages: undefined,
        responsibleUserIds: undefined,
        escalatedUserIds: undefined,
        updatedAtDateFilter: undefined,
        types: [ContactFormType.contact],
      },
      pagination: {
        pageSize: pagination.pageSize,
        page: pagination.page + 1,
        sort: undefined,
      },
    },
  });
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] =
    useState<TNullable<IBaseContactFormEntity>>(null);
  const [selectedStatus, setSelectedStatus] =
    useState<ContactFormStatus | null>(null);

  // queries
  const {
    data: contactFormsData,
    isLoading: contactFormsIsLoading,
    error: contactFormsError,
    refetch: refetchContactForms,
  } = useContactFormsQuery(variables);

  const {
    data: usersData,
    isLoading: usersIsLoading,
    error: usersError,
  } = useUsersQuery({
    input: {
      pagination: {
        sort: { createdAt: -1 },
      },
    },
  });

  // mutations

  const [
    updateContactForm,
    {
      data: updateContactFormData,
      isLoading: updateContactFormIsLoading,
      error: updateContactFormError,
    },
  ] = useUpdateContactFormMutation();

  // constants

  // action handlers
  const handleClickStatus = (event: any, item: IBaseContactFormEntity) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
    setSelectedRow(selectedRow?._id === item._id ? null : item);
    setSelectedStatus(null);
  };

  const handleClickAway = () => {
    setOpen(false);
    setAnchorEl(null);
    setSelectedRow(null);
    setSelectedStatus(null);
  };

  const handleSelectStatus = (status: ContactFormStatus) => {
    setSelectedStatus(status);
  };

  const handleChangeStatus = (item: IBaseContactFormEntity) => {
    updateContactForm({
      filter: { _id: item._id },
      input: {
        status: selectedStatus as ContactFormStatus,
      },
    });
  };

  const handleEdit = (id: string) => {
    navigate(updateContactFormRoute(id));
  };

  const handleDelete = (item: IBaseContactFormEntity) => {
    setSelectedItemForDelete(item);
  };

  const handleView = (id: string) => {
    navigate(viewContactFormRoute(id));
  };

  const handleSortRequest = (sortName: string) => {
    sortName === "index"
      ? setVariables({
          ...variables,
          input: {
            ...variables.input,
            pagination: {
              ...variables.input?.pagination,
              sort: undefined,
            },
          },
        })
      : setVariables({
          ...variables,
          input: {
            ...variables.input,
            pagination: {
              ...variables.input?.pagination,
              sort: {
                [sortName]:
                  variables.input?.pagination?.sort?.[sortName] === 1 ? -1 : 1,
              },
            },
          },
        });
  };

  // useEffects.data
  useEffect(() => {
    if (updateContactFormData) {
      setAnchorEl(null);
      setOpen(false);
      setSelectedRow(null);
      setSelectedStatus(null);

      enqueueSnackbar(t("main.contactForm.contactForm.updatedSuccessfully"), {
        variant: "success",
      });
    }
  }, [updateContactFormData]);

  // useEffects.error
  useEffect(() => {
    DefaultErrorHandlerUseEffect(
      contactFormsError as IBaseError,
      currentLanguage
    );
  }, [contactFormsError, currentLanguage]);

  // useEffect(() => {
  //   DefaultErrorHandlerUseEffect(usersError as IBaseError, currentLanguage);
  // }, [usersError, currentLanguage]);

  // useEffects.init
  useEffect(() => {
    setVariables({
      input: {
        filter: {
          types: [ContactFormType.contact],
        },
        pagination: {
          pageSize: pagination.pageSize,
          page: pagination.page + 1,
        },
      },
    });
  }, [pagination]);

  useEffect(() => {
    dispatch(
      contactFormActions.setTitle(
        t("main.contactForm.contactForm.contactForms")
      )
    );
    dispatch(contactFormActions.setBackButton(false));
    dispatch(contactFormActions.setRightButton(null));
  }, [dispatch]);

  console.log(variables);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Grid item xs={12}>
        {/* Filters */}
        <Stack
          direction={"column"}
          gap={2}
          mb={2}
          border={"1px solid #CDCFD1"}
          borderRadius={2}
          p={2}
        >
          <Typography variant="h5">
            {t("main.contactForm.contactForm.filters")}
          </Typography>
          <Stack direction={{ xs: "column", md: "row" }} gap={2}>
            <TextField
              fullWidth
              label={t("main.contactForm.contactForm.title")}
              value={variables.input?.filter?.fullName}
              onChange={(e) => {
                setVariables({
                  ...variables,
                  input: {
                    ...variables.input,
                    filter: {
                      ...(variables.input?.filter || {}),
                      fullName: e.target.value,
                    },
                  },
                });
              }}
            />

            <TextField
              fullWidth
              label={t("main.contactForm.contactForm.email")}
              value={variables.input?.filter?.email}
              onChange={(e) => {
                setVariables({
                  ...variables,
                  input: {
                    ...variables.input,
                    filter: {
                      ...(variables.input?.filter || {}),
                      email: e.target.value,
                    },
                  },
                });
              }}
            />

            <Autocomplete
              id="statuses"
              fullWidth
              options={Object.values(ContactFormStatus).map((status) => ({
                name: status,
                _id: status,
              }))}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("main.contactForm.contactForm.statuses")}
                />
              )}
              multiple
              value={
                variables.input?.filter?.statuses &&
                variables.input.filter.statuses.length > 0
                  ? variables.input.filter.statuses.map((status) => ({
                      name: status,
                      _id: status,
                    }))
                  : undefined
              }
              onChange={(e, value) => {
                setVariables({
                  ...variables,
                  input: {
                    ...variables.input,
                    filter: {
                      ...(variables.input?.filter || {}),
                      statuses:
                        value?.length > 0
                          ? value.map((status) => status._id)
                          : undefined,
                    },
                  },
                });
              }}
            />
          </Stack>
          <Stack direction={{ xs: "column", md: "row" }} gap={2}>
            <Autocomplete
              id="responsibleUser"
              isOptionEqualToValue={(option, value) => option._id === value._id}
              fullWidth
              getOptionLabel={(option) =>
                option.name + " " + option.surname || ""
              }
              options={usersData?.data || []}
              loading={usersIsLoading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("main.contactForm.contactForm.responsibleUser")}
                />
              )}
              multiple
              value={
                variables.input?.filter?.responsibleUserIds &&
                variables.input.filter.responsibleUserIds.length > 0
                  ? usersData?.data?.filter((responsibleUser) =>
                      variables?.input?.filter?.responsibleUserIds?.includes(
                        responsibleUser._id
                      )
                    )
                  : undefined
              }
              onChange={(e, value) => {
                setVariables({
                  ...variables,
                  input: {
                    ...variables.input,
                    filter: {
                      ...(variables.input?.filter || {}),
                      responsibleUserIds:
                        value?.length > 0
                          ? value.map((responsibleUser) => responsibleUser._id)
                          : undefined,
                    },
                  },
                });
              }}
            />

            <Autocomplete
              id="escalatedUser"
              isOptionEqualToValue={(option, value) => option._id === value._id}
              fullWidth
              getOptionLabel={(option) =>
                option.name + " " + option.surname || ""
              }
              options={usersData?.data || []}
              loading={usersIsLoading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("main.contactForm.contactForm.escalatedUser")}
                />
              )}
              multiple
              value={
                variables.input?.filter?.escalatedUserIds &&
                variables.input.filter.escalatedUserIds.length > 0
                  ? usersData?.data?.filter((escalatedUser) =>
                      variables?.input?.filter?.escalatedUserIds?.includes(
                        escalatedUser._id
                      )
                    )
                  : undefined
              }
              onChange={(e, value) => {
                setVariables({
                  ...variables,
                  input: {
                    ...variables.input,
                    filter: {
                      ...(variables.input?.filter || {}),
                      escalatedUserIds:
                        value?.length > 0
                          ? value.map((escalatedUser) => escalatedUser._id)
                          : undefined,
                    },
                  },
                });
              }}
            />

            <DateRangePicker
              sx={{ width: "100%" }}
              label={t("main.contactForm.contactForm.updatedAtDateFilter")}
              value={
                variables.input?.filter?.updatedAtDateFilter
                  ? [
                      dayjs(variables.input.filter.updatedAtDateFilter.from),
                      dayjs(variables.input.filter.updatedAtDateFilter.to),
                    ]
                  : undefined
              }
              onChange={(e) => {
                setVariables({
                  ...variables,
                  input: {
                    ...variables.input,
                    filter: {
                      ...(variables.input?.filter || {}),
                      updatedAtDateFilter: {
                        from: e?.[0]?.toDate() || undefined,
                        to: e?.[1]?.toDate() || undefined,
                      },
                    },
                  },
                });
              }}
            />
          </Stack>
        </Stack>

        <Card sx={{ p: 2 }}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={
                        variables.input?.pagination?.sort?.index !== undefined
                      }
                      direction={
                        variables.input?.pagination?.sort?.index === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortRequest("index")}
                    >
                      #
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={
                        variables.input?.pagination?.sort?.fullName !==
                        undefined
                      }
                      direction={
                        variables.input?.pagination?.sort?.fullName === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortRequest("fullName")}
                    >
                      {t("fullName")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={
                        variables.input?.pagination?.sort?.email !== undefined
                      }
                      direction={
                        variables.input?.pagination?.sort?.email === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortRequest("email")}
                    >
                      {t("email")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={
                        variables.input?.pagination?.sort?.subject !== undefined
                      }
                      direction={
                        variables.input?.pagination?.sort?.subject === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortRequest("subject")}
                    >
                      {t("subject")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={
                        variables.input?.pagination?.sort?.status !== undefined
                      }
                      direction={
                        variables.input?.pagination?.sort?.status === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortRequest("status")}
                    >
                      {t("status")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={
                        variables.input?.pagination?.sort?.createdAt !==
                        undefined
                      }
                      direction={
                        variables.input?.pagination?.sort?.createdAt === 1
                          ? "asc"
                          : "desc"
                      }
                      onClick={() => handleSortRequest("createdAt")}
                    >
                      {t("createdAt")}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="right">{t("actions")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!contactFormsIsLoading
                  ? contactFormsData?.data?.map((row, index) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell>
                          {(pagination.page ?? 0) * pagination.pageSize +
                            index +
                            1}
                        </TableCell>
                        <TableCell>{row.fullName}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>{row.subject}</TableCell>
                        <TableCell>
                          <Chip
                            label={row.status}
                            aria-describedby={row._id}
                            onClick={(
                              event: React.MouseEvent<HTMLDivElement>
                            ) => {
                              handleClickStatus(event, row);
                            }}
                            style={{ cursor: "pointer" }}
                          />
                          <StatusPopper
                            open={selectedRow?._id === row._id && open}
                            anchorEl={anchorEl}
                            dataList={Object.values(ContactFormStatus)}
                            row={row}
                            handleEdit={handleEdit}
                            handleSelectStatus={handleSelectStatus}
                            handleChangeStatus={handleChangeStatus}
                            selectedStatus={selectedStatus as ContactFormStatus}
                            loading={updateContactFormIsLoading}
                          />
                        </TableCell>
                        <TableCell>
                          {moment(row.createdAt).format("DD.MM.YYYY HH:mm")}
                        </TableCell>
                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent={"flex-end"}
                            gap={1}
                            flexWrap={"wrap"}
                          >
                            <Button
                              onClick={() => handleView(row._id)}
                              variant="outlined"
                              size="small"
                              sx={{ height: 32, minWidth: 10 }}
                            >
                              <RemoveRedEye sx={{ fontSize: "16px" }} />
                            </Button>
                            <Button
                              onClick={() => handleEdit(row._id)}
                              variant="outlined"
                              size="small"
                              sx={{ height: 32, minWidth: 10 }}
                            >
                              <Edit sx={{ fontSize: "16px" }} />
                            </Button>
                            <Button
                              onClick={() => handleDelete(row)}
                              variant="outlined"
                              size="small"
                              color="error"
                              sx={{ height: 32, minWidth: 10 }}
                            >
                              <DeleteIcon sx={{ fontSize: "16px" }} />
                            </Button>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))
                  : [1, 2, 3].map((key) => (
                      <TableRow key={key}>
                        <TableCell>
                          <Skeleton variant="text" sx={{ width: "100%" }} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" sx={{ width: "100%" }} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" sx={{ width: "100%" }} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" sx={{ width: "100%" }} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" sx={{ width: "100%" }} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" sx={{ width: "100%" }} />
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>

        <Stack
          direction="row"
          justifyContent={{ xs: "center", md: "flex-start" }}
          mt={2}
          mb={2}
        >
          <MyTablePagination
            count={contactFormsData?.count ?? 0}
            pageSize={pagination.pageSize}
            page={pagination.page ?? 0}
            onPageChange={(e, page) => {
              setPagination({
                ...pagination,
                page: page,
              });
            }}
            onRowsPerPageChange={(e) => {
              setPagination({
                page: 0,
                pageSize: parseInt(e.target.value),
              });
            }}
          />
        </Stack>

        <Delete
          open={!!selectedItemForDelete}
          item={selectedItemForDelete!}
          onClose={() => {
            setSelectedItemForDelete(null);
          }}
        />
      </Grid>
    </ClickAwayListener>
  );
};

export default List;
